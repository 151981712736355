<template>
  <div>
    <Spinner v-if="$store.state.loadSpinner" />

    <div v-if="can('dashboard.stats')">
      <WidgetsDropdown :statistics="statistics" v-if="statistics" />
  
      <StatChartComponent v-if="serviceChartDataPerMonth" headerTitle="معدل اضافة الخدمات لكل شهر" :barData="serviceChartDataPerMonth" entity="services" />
      <StatChartComponent v-if="userChartDataPerMonth" headerTitle="معدل تسجيل العملاء لكل شهر" secondHeaderTitle="حالات العملاء" :barData="userChartDataPerMonth" entity="users" />
    </div>


  </div>
</template>

<script>
import WidgetsDropdown from "./WidgetsDropdown";
import StatChartComponent from "./StatChartComponent";

export default {
  name: "Dashboard",
  components: {
    WidgetsDropdown,
    StatChartComponent
  },
  data() {
    return {
      statistics: null,
      serviceChartDataPerMonth: null,
      userChartDataPerMonth: null
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    // dashboard
    fetchData() {
      this.toggleLoadSpinner();

      this.$http
        .get(`/stats`)
        .then((res) => {
          // handle success
          this.statistics = res.data.data;

          this.serviceChartDataPerMonth = res.data.data.servicesPerMonth;
          this.userChartDataPerMonth = res.data.data.usersPerMonth;

          this.toggleLoadSpinner();
        })
        .catch(() => {
          //
          this.toggleLoadSpinner();
        });
    },
    toggleLoadSpinner() {
      this.$store.commit("toggleLoadSpinner");
    }
  },
};
</script>
