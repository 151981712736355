var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',{staticClass:"mb-5"},[(_vm.showBar)?_c('CCol',{attrs:{"sm":"12","md":"12"}},[_c('CCard',[_c('CCardHeader',[_vm._v(" "+_vm._s(_vm.headerTitle)+" ")]),_c('CCardBody',[_c('CChartBar',{attrs:{"datasets":_vm.barChartDataset,"labels":_vm.barChartLabels,"options":{
          responsive: true,
          scales: {
            xAxes: [
              {
                ticks: {
                  maxRotation: 12,
                  minRotation: 1,
                },
              } ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  stepSize: 1,
                },
              } ],
          },
        }}})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }