<template>
  <CRow class="mb-5">
    <CCol sm="12" md="12" v-if="showBar">
      <CCard>
        <CCardHeader> {{ headerTitle}} </CCardHeader>
        <CCardBody>
          <CChartBar :datasets="barChartDataset" :labels="barChartLabels" :options="{
            responsive: true,
            scales: {
              xAxes: [
                {
                  ticks: {
                    maxRotation: 12,
                    minRotation: 1,
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    stepSize: 1,
                  },
                },
              ],
            },
          }" />
        </CCardBody>
      </CCard>
    </CCol>
  
  </CRow>
</template>

<script>
import { CChartBar } from "@coreui/vue-chartjs";
export default {
  name: "StatChartComponent",
  components: {
    CChartBar,
  },
  props: ["headerTitle", "barData", "entity"],
  data() {
    return {
      statistics: null,
      showBar: false,
      showPie: false,
      barChartLabels: [],
      barChartDataset: [
        {
          label: "",
          data: [],
          backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(255, 159, 64, 0.2)",
            "rgba(255, 205, 86, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(153, 102, 255, 0.2)",
            "rgba(201, 203, 207, 0.2)",
          ],
          borderColor: [
            "rgb(255, 99, 132)",
            "rgb(255, 159, 64)",
            "rgb(255, 205, 86)",
            "rgb(75, 192, 192)",
            "rgb(54, 162, 235)",
            "rgb(153, 102, 255)",
            "rgb(201, 203, 207)",
          ],
          borderWidth: 1,
        },
      ],
    };
  },
  created() {
    this.handleBarChartData(this.barData);
  },
  methods: {
    handleBarChartData(data) {
      data.forEach((element) => {
        this.barChartLabels.push(element.month);
        this.barChartDataset[0].data.push(element[this.entity]);
      });
      this.showBar = true;
    },
  }
};
</script>
